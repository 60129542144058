//@ts-nocheck
export const appConfiguration = {
    API: {
        URL: window.API_URL || ""
    },
    LocalLogin: {
        active: window.LocalLogin || false
    },
    AUTH: {
        CLIENT_ID: window.CLIENT_ID || "",
        AUTHORITY: window.AUTHORITY || "",
        REDIRECT_URI: window.REDIRECT_URI || "",
        SCOPES: window.SCOPES || ""
    },
    SISTEMA: {
        NOMBRE: window.SISTEMA || "",
        SISTEMA_DYNAMIC: window.SISTEMA_DYNAMIC || '',
        SISTEMA_SALESFORCE: window.SISTEMA_SALESFORCE || '',
        SALESFORCE_ACTIVO: window.SISTEMA_SALESFORCE_DESACTIVADO || false,
    }
}
export default appConfiguration;